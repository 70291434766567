import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline } from 'images/koppelingen'
import { employes } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { azl } from 'images'

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'AZL als pensioenfonds voor levensmiddelen',
    description: 'Als ondernemer op het gebied van levensmiddelen val je verplicht onder het bedrijfstakpensioenfonds levensmiddelen. Daarvoor moet er pensioenaangifte gedaan worden bij AZL. Met Employes verzorgen we dit automatisch voor je via UPA (uniforme pensioenaangifte).'
        }, {
    title: 'Pensioenaangifte geautomatiseerd in Employes',
    description: 'Als ondernemer kom je waarschijnlijk al handen te kort en heb je hiernaast niet de tijd om je nog bezig te houden met ingewikkelde regelgevingen. Ook op het gebied van pensioenaangifte zorgt de software van Employes ervoor dat je hier geen omkijken meer naar hebt. Employes is volledig afgestemd op de regeling van AZL, waardoor altijd de juiste looncomponenten worden meegegeven in de pensioenaangifte. Employes levert deze gegevens automatisch aan bij je pensioensfonds. Zelf hoef je niks te doen.'
  }],

}

class Azl extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling AZL & Employes | Salarisadministratie</title>
            <meta name="description" content="Pensioenaangifte AZL geautomatiseerd in Employes."/>
            <meta itemprop="name" content="Koppeling AZL & Employes | Salarisadministratie| Pensioenaangifte" />
            <meta itemprop="description" content="Pensioenaangifte AZL geautomatiseerd in Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling azl padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Pensioenaangifte via AZL <span className="hide-mobile"> voor levensmiddelen</span></h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employes} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={azl}/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
              <div className="section margin-m-bottom">
                <div className="container-sm">
                  <div className="grid yg center">
                    <div className="col-12 padding-m">
                        <div className="text">
                          <h4>Over AZL als pensioenfonduitvoerder</h4>
                          <p>Als ondernemer op het gebied van levensmiddelen of houtverwerkende industrie wordt je pensioen verwerkt via AZL. Daarom moet er ook pensioenaangifte gedaan worden bij AZL. Met Employes verzorgen we dit automatisch voor je via UPA (uniforme pensioenaangifte). We doen pensioenaangifte voor de volgende fondsen:</p>
                          <ul>
                            <li>Bedrijfstakpensioenfonds voor het Levensmiddelenbedrijf</li>
                            <li>Bedrijfstakpensioenfonds voor de Houtverwerkende industrie en Jachtbouw</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section margin-m-bottom">
                <div className="container-sm">
                  <div className="grid yg center">
                    <div className="col-12 padding-m">
                        <div className="text">
                          <h4>Toepassing pensioenregelingen</h4>
                          <p>Binnen Employes passen we automatisch alle regelingen voor het pensioen en sociaal fonds toe. Dit zorgt ervoor dat er automatisch de juiste werknemersbijdrage uitgerekend wordt. En ook de werkgeverskosten worden bepaald. Na de verloning sturen we het resultaat op naar AZL.</p>
                          <p>Als je vragen hebt over hoe de pensioenaangifte werkt, dan horen we het graag. Je kunt hiervoor per mail of telefonisch contact opnemen.</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Azl.propTypes = {
  data: PropTypes.object
}

export default Azl
